import CryptoJS from 'crypto-js';

const TAE_KEY = process.env.REACT_APP_TAE_KEY;

export function decryptString(iv, text) {
    // Convert the text to a CipherParams object
    const ciphertext = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Base64.parse(text)
    });

    // Decrypt the text
    const decrypted = CryptoJS.AES.decrypt(
        ciphertext,
        CryptoJS.enc.Utf8.parse(TAE_KEY),
        {
            iv: CryptoJS.enc.Base64.parse(iv),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }
    );

    return decrypted.toString(CryptoJS.enc.Utf8);
}

export const getCookie = (name) => {
  return document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`))?.at(2);
};

export const deleteCookie = ( name, path, domain ) => {
  if( getCookie( name ) ) {
    document.cookie = name + "=" +
      ((path) ? ";path="+path:"")+
      ((domain)?";domain="+domain:"") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}

export const csrftoken = getCookie('csrftoken');
