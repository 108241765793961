import React, {useEffect, useState} from 'react';
import {Container, Navbar, Nav, Button} from 'react-bootstrap';
import {Outlet, Link} from "react-router-dom";
import axios from "axios";
import {csrftoken, deleteCookie, getCookie} from "./utils";

const Layout = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        // Check if the session cookie exists
        const ttt = getCookie('ttt');
        setIsLoggedIn(!!ttt); // Convert to boolean
    }, []);

    const handleLogout = async (e) => {
        e.preventDefault();

        try {
            // Call logout API
            const response = await axios.post(
                '/api/account/logout/',
                null,
                {headers: {"X-CSRFToken": csrftoken}}
            );

            if (response.status === 200) {
                // Remove session cookie
                deleteCookie('ttt', '/', window.location.hostname);

                // Update isLoggedIn state
                setIsLoggedIn(false);

                // Redirect to home page
                window.location.href = '/';
            }
        } catch (error) {
            console.log('Logout error:', error);
        }
    };

    return (
        <>
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand as={Link} to="/">Digital Scyther</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link as={Link} to="/">Home</Nav.Link>
                            <Nav.Link as={Link} to="/projects">Projects</Nav.Link>
                            <Nav.Link as={Link} to="/contacts">Contacts</Nav.Link>
                        </Nav>
                        <Nav>
                            {isLoggedIn ? (
                                <Button variant="outline-danger" onClick={handleLogout}>Logout</Button>
                            ) : (
                                <>
                                    <Nav.Link as={Link} to="/login">LogIn</Nav.Link>
                                    <Nav.Link as={Link} to="/signup">SignUp</Nav.Link>
                                </>
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <Outlet/>
        </>
    );
};

export default Layout;
