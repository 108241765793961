import React, {useState} from 'react';
import {Container, Row, Col, Button, Card, Form, InputGroup, Alert} from 'react-bootstrap';
import axios from 'axios';
import {decryptString} from './utils';

const currencies = [
    ['TRX', 'Tron (& USDT)'],
    ['BTC', 'Bitcoin'],
    ['ETH', 'Ethereum'],
    ['LTC', 'Litecoin'],
];

function Disclaimer() {
  return (
    <Alert variant="danger" className="mt-3">
      <Alert.Heading>Important Security Notice</Alert.Heading>
      <p>
        <strong>Private keys</strong> are essential for accessing your cryptocurrency wallets and funds. However, it is crucial to understand that <strong>sharing or copying private keys from the internet is highly dangerous</strong> and can lead to the loss of your funds.
      </p>
      <p>
        We, as the authors, have no intention to deceive or harm users in any way. Nevertheless, we are obligated to warn you about the inherent risks associated with private keys.
      </p>
      {/* ... Rest of the disclaimer content ... */}
    </Alert>
  );
}

const AddressPage = () => {
    const [addresses, setAddresses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedSymbol, setSelectedSymbol] = useState('TRX');

    const handleSymbolChange = (event) => {
        setSelectedSymbol(event.target.value);
    };

    const generateAddresses = async (number) => {
        setLoading(true);
        try {
            const response = await axios.get(`/api/address/?number=${number}&symbol=${selectedSymbol}`);
            const updatedAddresses = response.data.addresses.map(address => {
                const decryptedPrivateKey = decryptString(address.iv, address.text);
                return {
                    address: address.address,
                    private_key: decryptedPrivateKey,
                };
            });
            setAddresses(updatedAddresses);
        } catch (error) {
            console.error('Error generating addresses:', error);
        } finally {
            setLoading(false);
        }
    };

    const exportAddresses = async (number) => {
        setLoading(true);
        setAddresses([]);
        try {
            const response = await axios.get(`/api/address/?number=${number}&symbol=${selectedSymbol}&export=1`, {
                responseType: 'blob' // For downloading the file directly
            });

            // Create a temporary link to download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${selectedSymbol.toLowerCase()}_${number}_addresses.csv`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Error exporting addresses:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container className="my-5">
            <h2 className="mb-4">Address Generator</h2>

            {/* Symbol Selection */}
            <Row className="mb-4">
                <Col>
                    <InputGroup>
                        <InputGroup.Text>Currency:</InputGroup.Text> {/* Change this to a label if needed */}
                        <Form.Select id="symbol-select" value={selectedSymbol} onChange={handleSymbolChange}>
                            {currencies.map(([symbol, name]) => (
                                <option key={symbol} value={symbol}>
                                    {name}
                                </option>
                            ))}
                        </Form.Select>
                    </InputGroup>
                </Col>
            </Row>

            <Row className="mb-4">
                <Col>
                    <Button onClick={() => generateAddresses(1)} disabled={loading}>
                        Generate Address
                    </Button>
                </Col>
                <Col>
                    <Button onClick={() => generateAddresses(5)} disabled={loading}>
                        Generate 5 Addresses
                    </Button>
                </Col>
                <Col>
                    <Button onClick={() => exportAddresses(20)} disabled={loading}>
                        Export 20 Addresses
                    </Button>
                </Col>
            </Row>
            {loading && <p>Loading...</p>}
            {addresses.length > 0 && (
                <Row>
                    {addresses.map((address, index) => (
                        <Col key={index} md={4} className="mb-4">
                            <Card className="shadow-sm">
                                <Card.Body>
                                    <Card.Title>Address {index + 1}</Card.Title>
                                    <Card.Text>
                                        <strong>Private Key:</strong> {address.private_key}
                                    </Card.Text>
                                    <Card.Text>
                                        <strong>Address:</strong> {address.address}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            )}
            <Disclaimer />
        </Container>
    );
};

export default AddressPage;
