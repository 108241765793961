import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import Home from "./Home";
import AddressPage from "./Address";
import Projects from "./Projects";
import Contacts from "./Contacts";
import Login from "./Login";
import Signup from "./Signup";
import NoPage from "./NoPage";

function App() {
  return (
    <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              {/*<Route path="blogs" element={<Blogs />} />*/}
              <Route path="projects" element={<Projects />} />
              <Route path="contacts" element={<Contacts />} />
              <Route path="address" element={<AddressPage />} />
              <Route path="login" element={<Login />} />
              <Route path="signup" element={<Signup />} />
              <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
    </Router>
  );
}

export default App;
