import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

function Home() {
  return (
      <Container className="my-5 text-center">
          <h2>Welcome to Our Platform</h2>
          <p className="lead">Discover the power of Address Generator</p>
          <Row className="justify-content-center mt-4">
              <Col md={6}>
                  <p>
                      Our Address Generator tool allows you to securely generate private keys and corresponding
                      Tron (USDT), Bitcoin, Ethereum, and Litecoin addresses.
                  </p>
                  <p>
                      Whether you need a single address for personal use or multiple addresses for your project, our
                      tool has got you covered.
                  </p>
                  <Button href="/address" variant="primary">Get Started</Button>
              </Col>
          </Row>
          <hr/>
          {/* Add a horizontal line for visual separation */}
          <Row className="justify-content-center mt-4">
              <Col md={6}>
                  <h3>Our Projects</h3>
                  <p>
                      Explore our projects, both in progress and already created, to see what we're working on and what
                      we've accomplished.
                  </p>
                  <Button href="/projects" variant="info">View Projects</Button>
              </Col>
          </Row>
      </Container>
  );
}

export default Home;