import React, { Component } from 'react';
import axios from 'axios';
import { Badge, Button, Card, Container, Row, Col } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';

class Project extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [],
            isCopied: {}
        };
    }

    componentDidMount() {
        axios.get('/api/project/')
            .then(response => {
                this.setState({ projects: response.data });
            })
            .catch(error => console.error('There was an error!', error));
    }

    formatBalance = (balance) => {
        return Number(balance).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        });
    };

    render() {
        return (
            <Container>
                <h2 className="mt-4 mb-3">Projects</h2>
                <Row xs={1} md={2} lg={3} xl={4} className="g-3">
                    {this.state.projects.map(project => (
                        <Col key={project.id}>
                            <Card className="h-100">
                                <Card.Body>
                                    <Card.Title>{project.name}</Card.Title>
                                <Badge bg={getStatusVariant(project.status)} className="mb-2">{project.status}</Badge>
                                <Card.Text>{project.short_description}</Card.Text>
                                {project.url && (
                                    <Button href={project.url} variant="primary" className="me-2">
                                        Go To
                                    </Button>
                                )}
                                <LastUpdated lastModified={project.last_time_modified}/>
                                    {project.wallet && project.wallet.active && (
                                        <div>
                                            <p>
                                                <strong>Support TRC20 USDT Address</strong>
                                            <br/>
                                            <code>{project.wallet.address}</code>
                                                <CopyToClipboard text={project.wallet.address}
                                                                 onCopy={() => {
                                                                     this.setState({
                                                                         ...this.state,
                                                                         isCopied: {
                                                                             ...this.state.isCopied,
                                                                             [project.id]: true
                                                                         }
                                                                     });
                                                                     setTimeout(() => this.setState({
                                                                         ...this.state,
                                                                         isCopied: {
                                                                             ...this.state.isCopied,
                                                                             [project.id]: false
                                                                         }
                                                                     }), 3000);
                                                                 }}>
                                                    <Button variant="outline-secondary" size="sm" className="ms-2">
                                                        Copy Address
                                                    </Button>
                                                </CopyToClipboard>
                                                {this.state.isCopied[project.id] && <Badge bg="success" className="ms-3">Copied!</Badge>}
                                                <br/>
                                            <strong>Balance:</strong> {this.formatBalance(project.wallet.usdt)}
                                            </p>
                                        </div>
                                    )}
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        );
    }
}

const getStatusVariant = (status) => {
    switch (status) {
        case 'resource_discovery':
            return 'dark';
        case 'in_progress':
            return 'warning';
        case 'test':
            return 'info';
        case 'done':
            return 'success';
        default:
            return 'secondary';
    }
};

const LastUpdated = ({lastModified}) => {
    const lastModifiedTime = new Date(lastModified);
    // Using options to customize the datetime format
    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true // Optional for 12-hour clock
    };

    return (
        <Card.Text>
            Last updated: {lastModifiedTime.toLocaleString('en-US', options)}
        </Card.Text>
    );
};

export default Project;
