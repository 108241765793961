import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Card from 'react-bootstrap/Card';

function Contact() {
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    axios.get('/api/contact/')
      .then(response => {
        setContacts(response.data);
      })
      .catch(error => console.error('There was an error!', error));
  }, []);

  return (
    <div>
      <h2>Contacts</h2>
        {contacts.map(contact => (
            <Card key={contact.id}>
              <Card.Body>
                  <Card.Title>{contact.type}</Card.Title>
                  <Card.Text>
                      <a href={contact.value} target="_blank">{contact.value}</a>
                  </Card.Text>
              </Card.Body>
            </Card>
        ))}
    </div>
  );
}

export default Contact;